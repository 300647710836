import BaseMenu from './menu.vue';
export default {
  components: {
    BaseMenu
  },
  methods: {
    toLastPage() {
      this.$router.back();
    }
  }
};